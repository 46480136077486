import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './app/App'
import './i18n'
import * as serviceWorker from './serviceWorker'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
// import AlertTemplate from 'react-alert-template-basic'
import ReactHtmlParser from 'react-html-parser'

const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}

const AlertTemplate = ({ style, options, message, close }) => (
  <div
    style={{
      background: 'white',
      color: 'black',
      padding: '5px 10px',
      borderRadius: '5px',
      marginTop: '70px'
    }}
    className='d-flex'
  >
    <div className=' d-flex align-items-center'>
      {options.type === 'info' && <i className='mdi mdi-information mr-2' />}
      {options.type === 'success' && (
        <i className='mdi mdi-check-circle mr-2' />
      )}
      {options.type === 'error' && (
        <i style={{ color: 'red' }} className='mdi mdi-information mr-2' />
      )}
    </div>
    <div style={{ fontSize: '13px' }}>{ReactHtmlParser(message)}</div>
    {/* <button onClick={() => close()}>
      <i className='mdi mdi-close' />
    </button> */}
  </div>
)

ReactDOM.render(
  <BrowserRouter basename='/'>
    <AlertProvider
      template={AlertTemplate}
      {...options}
      containerStyle={{ zIndex: '400000' }}
    >
      <App />
    </AlertProvider>
  </BrowserRouter>,
  document.getElementById('root')
)

serviceWorker.unregister()