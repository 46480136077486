import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
// auth provider
import AuthContext from '../contexts/AuthContext'

const useAuth = () => {
  const context = useContext(AuthContext)
  const history = useHistory()

  if (context.isLoggedIn == false && history.location.pathname != '/') {
    history.replace('/')
  }

  if (!context) throw new Error('context must be use inside provider')

  return context
}

export default useAuth
