import React, { Component, Suspense, lazy, useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Spinner from '../app/shared/Spinner'
import useAuth from './hooks/useAuth'

const Landing = lazy(() => import('./pages/landing'))
const Dashboard = lazy(() => import('./pages/dashboard/Dashboard'))
const MatchMaking = lazy(() => import('./pages/match/MatchMaking'))
const Profile = lazy(() => import("./pages/profile"))
const News = lazy(() => import("./pages/news"))

const ChartJs = lazy(() => import('./charts/ChartJs'))

const Error404 = lazy(() => import('./error-pages/Error404'))
const Error500 = lazy(() => import('./error-pages/Error500'))

const Login = lazy(() => import('./pages/auth/Login'))
const Register1 = lazy(() => import('./pages/auth/Register'))
const Chat = lazy(() => import('./pages/chatbox'))
const Wallet = lazy(() => import('./pages/wallet'))
const Transactions = lazy(() => import('./pages/wallet/alltransactions'))
const UserSupport = lazy(() => import('./pages/user-support'))

const MarketPlace = lazy(() => import('./pages/marketplace'))

const Admin_Dashboard = lazy(() => import('./admin/dashboard'))
const Admin_Users = lazy(() => import('./admin/users'))
const Admin_Support = lazy(() => import('./admin/support'))
const Admin_Settings = lazy(() => import('./admin/settings'))
const Admin_News = lazy(() => import('./admin/news'))
const Admin_SetUp = lazy(() => import('./admin/setup'))
const Admin_Header = lazy(() => import('./admin/setup/Header'))
const Admin_Footer = lazy(() => import('./admin/setup/Footer'))
const Admin_Pages = lazy(() => import('./admin/setup/Pages'))

const AppRoutes = () => {
  const { isLoggedIn } = useAuth()
  // console.log({ isLoggedIn })
  // useEffect(() => {
  //   console.log('Changed')
  // }, [isLoggedIn])
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route exact path='/' component={Landing} />

        {isLoggedIn ? (
          <>
            <Route exact path='/chat' component={Chat} />
            <Route exact path='/dashboard' component={Dashboard} />
            <Route exact path='/match_making' component={MatchMaking} />
            <Route exact path='/advanced_search' component={MarketPlace} />
            <Route exact path='/marketplace' component={MarketPlace} />
            <Route exact path='/profile' component={Profile} />
            <Route exact path='/wallet' component={Wallet} />
            <Route exact path='/news' component={News} />
            <Route exact path='/wallet/all-transactions' component={Transactions} />
            <Route exact path='/user_support' component={UserSupport} />
            <Route exact path='/admin_dashboard' component={Admin_Dashboard} />
            <Route exact path='/admin_users' component={Admin_Users} />
            <Route exact path='/admin_support' component={Admin_Support} />
            <Route exact path='/admin_news' component={Admin_News} />
            <Route exact path='/settings' component={Admin_Settings} />
            <Route exact path='/setup' component={Admin_SetUp} />
            <Route exact path='/setup-header' component={Admin_Header} />
            <Route exact path='/setup-footer' component={Admin_Footer} />
            <Route exact path='/setup-pages' component={Admin_Pages} />
          </>
        ) : (
          <>{/* <Redirect to='/' /> */}</>
        )}

        {/* <Route path='/charts/chart-js' component={ChartJs} />

        <Route path='/pages/auth/login' component={Login} />
        <Route path='/pages/auth/register' component={Register1} />

        <Route path='/error-pages/error-404' component={Error404} />
        <Route path='/error-pages/error-500' component={Error500} /> */}
      </Switch>
    </Suspense>
  )
}

export default AppRoutes
