import React, { useState, useEffect} from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Collapse, Dropdown } from 'react-bootstrap'
import {FaUsers} from "react-icons/fa"
import {MdContactSupport, MdWifiProtectedSetup, MdSettings} from "react-icons/md"
import { Trans } from 'react-i18next'
import useAuth from '../hooks/useAuth'

const Sidebar = (props) => {
  const [menuState, setMenuState] = useState(true)
  const {isLoggedIn, user} = useAuth()

  useEffect(() =>  {
    onRouteChanged()
    const body = document.querySelector('body')
    document.querySelectorAll('.sidebar .nav-item').forEach(el => {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open')
        }
      })
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open')
        }
      })
    })
  },[])

  const isPathActive = (path) => {
    return props.location.pathname.startsWith(path)
  }

  const onRouteChanged = () => {
    document.querySelector('#sidebar').classList.remove('active')
    Object.keys(menuState).forEach(i => {
      setMenuState({ [i]: false })
    })

    const dropdownPaths = [
      { path: '/apps', state: 'appsMenuOpen' },
      { path: '/basic-ui', state: 'basicUiMenuOpen' },
      { path: '/form-elements', state: 'formElementsMenuOpen' },
      { path: '/tables', state: 'tablesMenuOpen' },
      { path: '/icons', state: 'iconsMenuOpen' },
      { path: '/charts', state: 'chartsMenuOpen' },
      { path: '/user-pages', state: 'userPagesMenuOpen' },
      { path: '/error-pages', state: 'errorPagesMenuOpen' }
    ]

    dropdownPaths.forEach(obj => {
      if (isPathActive(obj.path)) {
        setMenuState({ [obj.state]: true })
      }
    })
  }
  const toggleSetUpPages = (e) => {
    e.preventDefault()
    let setUpPages = document.getElementById("webSetUpPages")
    if(setUpPages.style.display === "none"){
      setUpPages.style.display = "block"
    }else{
      setUpPages.style.display = "none"
    }
  }

  return (
    <nav className='sidebar sidebar-offcanvas' id='sidebar'>
      <div className='sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top'>
        <a className='sidebar-brand brand-logo text-white'>
          <div>B2B By E4Impact</div>
          {/* <img src={require('../../assets/images/logo.jpeg')} alt='logo' /> */}
        </a>
        <a className='sidebar-brand brand-logo-mini text-white'>
          <div>B2B</div>
        </a>
      </div>
      {(isLoggedIn && user.is_super_admin === 0) && <ul className='nav'>
        <li
          className={
            isPathActive('/dashboard')
              ? 'nav-item menu-items active'
              : 'nav-item menu-items'
          }
        >
          <Link className='nav-link' to='/dashboard'>
            <span className='menu-icon'>
              <i className='mdi mdi-speedometer'></i>
            </span>
            <span className='menu-title'>
              <Trans>Dashboard</Trans>
            </span>
          </Link>
        </li>
        <li
          className={
            isPathActive('/advanced_search')
              ? 'nav-item menu-items active'
              : 'nav-item menu-items'
          }
        >
          <Link className='nav-link' to='/advanced_search'>
            <span className='menu-icon'>
              <i className='mdi mdi-magnify-plus'></i>
            </span>
            <span className='menu-title'>
              <Trans>Advanced Search</Trans>
            </span>
          </Link>
        </li>
        <li
          className={
            isPathActive('/match_making')
              ? 'nav-item menu-items active'
              : 'nav-item menu-items'
          }
        >
          <Link className='nav-link' to='/match_making'>
            <span className='menu-icon'>
              <i className='mdi mdi-link'></i>
            </span>
            <span className='menu-title'>
              <Trans>Match Making</Trans>
            </span>
          </Link>
        </li>
        <li
          className={
            isPathActive('/chat')
              ? 'nav-item menu-items active'
              : 'nav-item menu-items'
          }
        >
          <Link className='nav-link' to='/chat'>
            <span className='menu-icon'>
              <i className='mdi mdi-chat'></i>
            </span>
            <span className='menu-title'>
              <Trans>Chat</Trans>
            </span>
          </Link>
        </li>
        <li
          className={
            isPathActive('/wallet')
              ? 'nav-item menu-items active'
              : 'nav-item menu-items'
          }
        >
          <Link className='nav-link' to='/wallet'>
            <span className='menu-icon'>
              <i className='mdi mdi-wallet'></i>
            </span>
            <span className='menu-title'>
              <Trans>Wallet</Trans>
            </span>
          </Link>
        </li>
        <li
          className={
            isPathActive('/news')
              ? 'nav-item menu-items active'
              : 'nav-item menu-items'
          }
        >
          <Link className='nav-link' to='/news'>
            <span className='menu-icon'>
              <i className='mdi mdi-newspaper-variant-multiple'></i>
            </span>
            <span className='menu-title'>
              <Trans>News</Trans>
            </span>
          </Link>
        </li>
        <li
          className={
            isPathActive('/user_support')
              ? 'nav-item menu-items active'
              : 'nav-item menu-items'
          }
        >
          <Link className='nav-link' to='/user_support'>
            <span className='menu-icon'>
              <i className='mdi mdi-frequently-asked-questions'></i>
            </span>
            <span className='menu-title'>
              <Trans>User Support</Trans>
            </span>
          </Link>
        </li>
      </ul>}
      {(isLoggedIn && user.is_super_admin === 1) && <ul className='nav'>
        <li
          className={
            isPathActive('/admin_dashboard')
              ? 'nav-item menu-items active'
              : 'nav-item menu-items'
          }
        >
          <Link className='nav-link' to='/admin_dashboard'>
            <span className='menu-icon'>
              <i className='mdi mdi-speedometer'></i>
            </span>
            <span className='menu-title'>
              <Trans>Admin Dashboard</Trans>
            </span>
          </Link>
        </li>
        <li
          className={
            isPathActive('/admin_users')
              ? 'nav-item menu-items active'
              : 'nav-item menu-items'
          }
        >
          <Link className='nav-link' to='/admin_users'>
            <span className='menu-icon' style={{fontSize:"22px"}}>
              <FaUsers/>
            </span>
            <span className='menu-title'>
              <Trans>Users</Trans>
            </span>
          </Link>
        </li>
        <li
          className={
            isPathActive('/admin_support')
              ? 'nav-item menu-items active'
              : 'nav-item menu-items'
          }
        >
          <Link className='nav-link' to='/admin_support'>
            <span className='menu-icon' style={{fontSize:"22px"}}>
              <MdContactSupport/>
            </span>
            <span className='menu-title'>
              <Trans>Support</Trans>
            </span>
          </Link>
        </li>
        <li
          className={
            isPathActive('/settings')
              ? 'nav-item menu-items active'
              : 'nav-item menu-items'
          }
        >
          <Link className='nav-link' to='/settings'>
            <span className='menu-icon' style={{fontSize:"22px"}}>
              <MdSettings/>
            </span>
            <span className='menu-title'>
              <Trans>Settings</Trans>
            </span>
          </Link>
        </li>
        <li
          className={
            isPathActive('/admin_news')
              ? 'nav-item menu-items active'
              : 'nav-item menu-items'
          }
        >
          <Link className='nav-link' to='/admin_news'>
            <span className='menu-icon'>
              <i className='mdi mdi-newspaper-variant-multiple'></i>
            </span>
            <span className='menu-title'>
              <Trans>News and Information</Trans>
            </span>
          </Link>
        </li>
        <li
          className={
            isPathActive('/setup-pages')
              ? 'nav-item menu-items active'
              : 'nav-item menu-items'
          }
          onClick= {toggleSetUpPages}
        >
          <Link className='nav-link' to='/setup-pages'>
            <span className='menu-icon' style={{fontSize:"22px"}}>
              <MdWifiProtectedSetup/>
            </span>
            <span className='menu-title'>
              <Trans>Web SetUp</Trans>
            </span>
          </Link>
        </li>
        <div id="webSetUpPages" style={{display:"none"}}>
        <li
          className={
            isPathActive('/setup-header')
              ? 'nav-item menu-items active'
              : 'nav-item menu-items'
          }
        >
          <Link className='nav-link' to='/setup-header'>
            <span className='menu-icon'>
              
            </span>
            <span className='menu-title'>
              <Trans>Header</Trans>
            </span>
          </Link>
        </li>
        <li
          className={
            isPathActive('/setup-footer')
              ? 'nav-item menu-items active'
              : 'nav-item menu-items'
          }
        >
          <Link className='nav-link' to='/setup-footer'>
            <span className='menu-icon'>
              
            </span>
            <span className='menu-title'>
              <Trans>Footer</Trans>
            </span>
          </Link>
        </li>
        <li
          className={
            isPathActive('/setup-pages')
              ? 'nav-item menu-items active'
              : 'nav-item menu-items'
          }
        >
          <Link className='nav-link' to='/setup-pages'>
            <span className='menu-icon'>
              
            </span>
            <span className='menu-title'>
              <Trans>Pages</Trans>
            </span>
          </Link>
        </li>
        </div>
        {/* <li
          className={
            isPathActive('/basic-ui')
              ? 'nav-item menu-items active'
              : 'nav-item menu-items'
          }
        >
          <div
            className={
              state.basicUiMenuOpen
                ? 'nav-link menu-expanded'
                : 'nav-link'
            }
            onClick={() => toggleMenuState('basicUiMenuOpen')}
            data-toggle='collapse'
          >
            <span className='menu-icon'>
              <i className='mdi mdi-laptop'></i>
            </span>
            <span className='menu-title'>
              <Trans>Basic UI Elements</Trans>
            </span>
            <i className='menu-arrow'></i>
          </div>
          <Collapse in={state.basicUiMenuOpen}>
            <div>
              <ul className='nav flex-column sub-menu'>
                <li className='nav-item'>
                  {' '}
                  <Link
                    className={
                      isPathActive('/basic-ui/buttons')
                        ? 'nav-link active'
                        : 'nav-link'
                    }
                    to='/basic-ui/buttons'
                  >
                    <Trans>Buttons</Trans>
                  </Link>
                </li>
                <li className='nav-item'>
                  {' '}
                  <Link
                    className={
                      isPathActive('/basic-ui/dropdowns')
                        ? 'nav-link active'
                        : 'nav-link'
                    }
                    to='/basic-ui/dropdowns'
                  >
                    <Trans>Dropdowns</Trans>
                  </Link>
                </li>
                <li className='nav-item'>
                  {' '}
                  <Link
                    className={
                      isPathActive('/basic-ui/typography')
                        ? 'nav-link active'
                        : 'nav-link'
                    }
                    to='/basic-ui/typography'
                  >
                    <Trans>Typography</Trans>
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li> */}

        {/* <li className='nav-item nav-category'>
          <span className='nav-link'>
            <Trans>More</Trans>
          </span>
        </li> */}
      </ul>}
    </nav>
  )
}

export default withRouter(Sidebar)
