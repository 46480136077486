import axios from 'axios'
import React, { createContext, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
const setSession = (user, token, expires_in_seconds) => {
  if (token) {
    console.log('storage set')
    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('token', token)
    const token_expiry = moment(moment.now()).add('seconds', expires_in_seconds)
    localStorage.setItem('token_expiry', token_expiry.toString())
  } else {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    localStorage.removeItem('token_expiry')
  }
}

const AuthContext = createContext(null)

export const AuthProvider = ({ children }) => {
  const history = useHistory()

  const [user, setUser] = useState(null)
  const [isLoggedIn, setIsLoggedIn] = useState(null)

  const init = useCallback(async () => {
    try {
      const token = localStorage.getItem('token')
      const user = localStorage.getItem('user')
      const token_expiry = localStorage.getItem('token_expiry')

      if (token) {
        //check token expiry
        if (moment(token_expiry) > moment.now()) {
          setUser(JSON.parse(user))
          setIsLoggedIn(true)
          return token
        }
      }

      setSession(null, null, null)
      setUser(null)
      setIsLoggedIn(false)
    } catch (err) {
      setSession(null, null, null)
      setUser(null)
      setIsLoggedIn(false)
    }
  }, [])

  useEffect(() => {
    setTimeout(() => init(), 500)
  }, [isLoggedIn])
  const getToken = () => {
    const token = localStorage.getItem('token')
    const token_expiry = localStorage.getItem('token_expiry')

    if (token) {
      //check token expiry
      // console.log({ token_expiry })
      if (moment(token_expiry) <= moment.now()) {
        setIsLoggedIn(false)
        setSession(null, null, null)
        history.push('/')
        return null
      }
      return token
    } else {
      return null
    }
  }
  const login = async (email, password) => {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + '/api/auth/login/',
      {
        email,
        password
      }
    )
    if (response.status == 200) {
      setIsLoggedIn(true)
      const resp = response.data.data
      const token = resp.access_token
      const user = resp.user
      const expires_in_seconds = resp.expires_in_seconds

      console.log('response', resp)
      console.log('Success', { token, user })
      setSession(user, token, expires_in_seconds)
      setTimeout(() => {
        if(user.is_super_admin === 1){
          history.push('/admin_dashboard')
        }
        if(user.is_super_admin === 0){
          history.push('/match_making')
        }
      }, 500)
    } else {
      return 'error'
    }
  }

  //   const register = async (email, password, firstName, lastName) => {
  //     // todo: this flow need to be recode as it not verified
  //     const id = chance.bb_pin()
  //     const response = await axios.post('/api/account/register', {
  //       id,
  //       email,
  //       password,
  //       firstName,
  //       lastName
  //     })
  //     let users = response.data

  //     if (
  //       window.localStorage.getItem('users') !== undefined &&
  //       window.localStorage.getItem('users') !== null
  //     ) {
  //       const localUsers = window.localStorage.getItem('users')
  //       users = [
  //         ...JSON.parse(localUsers),
  //         {
  //           id,
  //           email,
  //           password,
  //           name: `${firstName} ${lastName}`
  //         }
  //       ]
  //     }

  //     window.localStorage.setItem('users', JSON.stringify(users))
  //   }

  const logout = () => {
    setSession(null)
    setUser(null)
    setIsLoggedIn(false)
    history.push('/')
  }

  //   const resetPassword = email => console.log(email)

  const updateProfile = () => {}

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoggedIn,
        getToken,
        login,
        logout,
        updateProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext